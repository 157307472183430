import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import
import { UserService } from "../../service/user.service";
import { load } from 'recaptcha-v3'
import ReCAPTCHA from 'react-google-recaptcha'

interface Props {
  handleClose: () => any;
  tryLogin: (id: any, pw: any) => any;

}

interface State {
  id: string;
  pass: string;
  token: string;
}

export class Login extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      id: "",
      pass: "",
      token : "",
    };
  }

  handleCheck = async () => {

    // const recaptcha = await load('6LfteZkfAAAAADfHG1KmVIUGkDfHIiMZSUCWoIGQ')
    //  const token = await recaptcha.execute('submit')


    if (
      this.state.id === undefined ||
      this.state.id.length <= 3 ||
      20 <= this.state.id.length
    ) {
      confirmAlert({
        title: "회원 로그인",
        message: "아이디와 비번을 정확히 입력해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }


    // if(this.state.token === "" || this.state.token.length < 4){

    //   confirmAlert({
    //     title: "캡챠",
    //     message: "캡챠를 확인해주세요",
    //     buttons: [
    //       {
    //         label: "확인",
    //         onClick: () => {},
    //       },
    //     ],
    //   });
    //   return 
    // }


    await this.props.tryLogin(this.state.id, this.state.pass)
  };


  

  onChange = (value : any) => {
    this.setState({token : value})
  }


  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
      >
        {(close) => (
          <div id="fade_0_1">
            <div className="close_wrap">
              <div className="close_box">
                <a
                  href="#"
                  className="fade_0_1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="/web/images/close.png" />
                </a>
              </div>
            </div>
            <div className="popup_wrap">
              <div className="popup_box">
                <div className="popup_start">
                  <div className="popup">
                    <div className="con_box10" style={{fontSize: "25px"}}>회원 로그인</div>
                    <div className="con_box10">
                      <table className="write_title_top">
                        <tr>
                          <td className="write_title">아이디</td>
                          <td className="write_basic">
                            <input
                              className="input3"
                              value={this.state.id}
                              onChange={(e: any) => {
                                this.setState({ id: e.target.value });
                              }}
                              style={{height : '28px', width : '100%'}}
                            />{" "}
                            {/* <a
                              onClick={() => {
                                this.handleCheck();
                              }}
                            >
                            </a> */}
                          </td>
                        </tr>

                        <tr>
                          <td className="write_title">비밀번호 </td>
                          <td className="write_basic">
                            <input
                              className="input3"
                              value={this.state.pass}
                              onChange={(e: any) => {
                                this.setState({ pass: e.target.value });
                              }}
                              style={{height : '32px', width : '100%'}}
                              type="password"
                            />{" "}
                          
                          </td>
                        </tr>

                        {/* <tr>
                          <td className="write_title">캡챠 </td>
                          <td className="write_basic">
                    
                    

                          <ReCAPTCHA
                            sitekey="6Lc5m9QfAAAAADoY2O2PKMjN7t-9iUVAAHjlEWPy"
                            onChange={this.onChange}
                          />

                          </td>
                        </tr> */}

                        <tr>
                            {
                                
                            }
{/* 
                                <Recaptcha
                                ref={ ref => this.captcha = ref }
                                sitekey="6LdthtobAAAAANTnVD-RwMWcYpFMMWMWacyfJZP9"
                                onResolved={ this.DoLogin }
                            /> */}
                        </tr>

                       
                     
                      </table>
                    </div>
                    <div className="con_box10">
                      <div className="btn_wrap_center">
                        <ul>
                          <li>
                            <a
                              onClick={() => {
                                this.handleCheck();
                              }}
                            >
                              <span className="btn3_1">회원 로그인</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}
