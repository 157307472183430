import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import CopyrightIcon from "@material-ui/icons/Copyright";
// const drawerWidth = 440;
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
} from "../../utility/help";
import { normalizeUnits } from "moment";
import { UserService } from "../../service/user.service";
import { SlotService } from "../../service/slot.service";

import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notie } from "./notie";
import { Reg } from "./reg";
import { Point } from "./point";
import { Comp } from "./comp";
import { Login } from "./login";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  point = "point",
  comp = "comp",

  reg = "reg",
  login = "login",
}
const CustomButton = styled(Button)`
  /* background-color: #6772e5; */
  /* color: #000;
  line-height: normal;
  font-size: 12px;
  font: bold; */

  box-shadow: inset 0px 1px 0px 0px #cf866c;
  background: linear-gradient(to bottom, #d0451b 5%, #bc3315 100%);
  background-color: #d0451b;
  border-radius: 3px;
  border: 1px solid #942911;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 16px;
  /* padding: 6px 24px; */
  text-decoration: none;
  width: 20%;
  text-shadow: 0px 1px 0px #854629;
`;

const styles = (theme: any) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },
});

const CustomToolbar = styled(Toolbar)`
  /* .muitoolbar-regular : {
  } */
`;

interface Props {
  classes: any;
  user: any;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;

  //   session: any;
}

interface State {
  ID: string;
  PW: string;

  balance: number;
  rolling: number;

  
  postCount: number;

  note: any;
  isOpen: boolean;

  popupStatuses: string;
  isBalanceupdate: boolean;
}
class topBar extends Component<Props, State> {
  static propTypes: { classes: PropTypes.Validator<object> };
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      rolling: 0,
      
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      isOpen: false,
      isBalanceupdate: false,
      popupStatuses: popupView.none,
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.handleUpdateNote();
    }, 30000);
    this.handleUpdateNote();

    setTimeout(() => {
      if (this.props.authenticated) {
        this.handleUpdateInBalance();
      }
    }, 1000);
  }

  handleUpdateInBalance = () => {
    this.setState({ isBalanceupdate: true });

    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balance: data.balance,
          rolling: data.rolling,
          
          isBalanceupdate: false,
        });
      } else {
      }
    });
  };

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          postCount: data.count,
          note: data.note,
          isOpen: data.count > 0 ? true : false,
        });

        if(data.count > 0){
          this.setState({popupStatuses: popupView.user});
        }

      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  render() {
    const classes = this.props.classes;
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.deposit) {
        return <Deposit handleClose={this.handleClosePopup}></Deposit>;
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
          ></Withdraw>
        );
      }
      if (this.state.popupStatuses === popupView.notice) {
        return <Notie handleClose={this.handleClosePopup}></Notie>;
      }

      if (this.state.popupStatuses === popupView.note) {
        return <Reg handleClose={this.handleClosePopup}></Reg>;
      }

      if (this.state.popupStatuses === popupView.reg) {
        return <Reg handleClose={this.handleClosePopup}></Reg>;
      }

      if (this.state.popupStatuses === popupView.help) {
        return <Help handleClose={this.handleClosePopup}></Help>;
      }
      if (this.state.popupStatuses === popupView.user) {
        return <User handleClose={this.handleClosePopup}></User>;
      }
      if (this.state.popupStatuses === popupView.point) {
        return <Point handleClose={this.handleClosePopup} user={user}></Point>;
      }
      if (this.state.popupStatuses === popupView.comp) {
        return <Comp handleClose={this.handleClosePopup} user={user}></Comp>;
      }
      
      if (this.state.popupStatuses === popupView.login) {
        return <Login 
          handleClose={this.handleClosePopup}  
          tryLogin={this.props.tryLogin}

        ></Login>;
      }
      

      return <div></div>;
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <div className="login">
            <ul>
              {/* <li>
                <input
                  name=""
                  type="text"
                  className="input_login"
                  placeholder="ID"
                  style={{
                    // backgroundImage: "url(/web/images/login_id.png)",
                    backgroundRepeat: "no-repeat",
                  }}
                  onChange={({ target: { value } }) =>
                    this.setState({ ID: value })
                  }
                />
              </li>
              <li>
                <input
                  name=""
                  placeholder="PW"
                  type="password"
                  className="input_login"
                  style={{
                    // backgroundImage: "url(/web/images/login_pw.png)",
                    backgroundRepeat: "no-repeat",
                  }}
                  onChange={({ target: { value } }) =>
                    this.setState({ PW: value })
                  }
                  // onFocus="this.style.backgroundImage = 'url(none)'"
                />
              </li> */}
              <li>
                <a
                  href="#"
                  onClick={() => {
                    // this.props.tryLogin(this.state.ID, this.state.PW);
                    this.setState({
                      popupStatuses: popupView.login,
                    });

                  }}
                >
                  <img src="/web/images/login_login.png" />
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="fade_0_1_open"
                  onClick={() => {
                    this.setState({
                      popupStatuses: popupView.reg,
                    });
                  }}
                >
                  <img src="/web/images/login_join.png" />
                </a>
              </li>
            </ul>
          </div>
        );
      }

      return (
        <div className="login">
          <ul>
            <li>
              <span className="font05">{user.id}</span> 님 환영합니다.
              &nbsp;&nbsp;&nbsp;&nbsp;
            </li>
            <li>
              보유금액{" "}
              <span className="font05"> {ConverMoeny(this.state.balance)}</span>
              원 &nbsp;&nbsp;&nbsp;&nbsp;

              콤프{" "}
              <span className="font05"> {ConverMoeny(this.state.rolling)}</span>
              원 &nbsp;&nbsp;&nbsp;&nbsp;

              {this.state.isBalanceupdate ? (
                <CircularProgress disableShrink size={20} />
              ) : (
                <RefreshIcon
                  onClick={() => {
                    this.handleUpdateInBalance();
                  }}
                ></RefreshIcon>
              )}
            </li>

            
            <li 
              onClick={() => {
                this.setState({popupStatuses: popupView.user});
              }}
            >
              쪽지 <span className="font05">({this.state.postCount})</span>통
              &nbsp;&nbsp;&nbsp;&nbsp;
            </li>
            <li>
              <a
                onClick={() => {
                  this.props.tryLoginOut();

                  this.setState({ isBalanceupdate: false });
                  window.location.reload();
                }}
              >
                <img src="/web/images/login_logout.png" />
              </a>
            </li>
          </ul>
        </div>
      );
    };

    return (
      <div className="header_wrap">
        <div
          className="logo"
          onClick={() => {
            window.location.reload();
          }}
        >
          <a>
          <img src="/web/images/logo.png" width="150" />
          </a>
        </div>
        <div className="top_wrap">
          <div className="top_box">
            <div className="language">
              <ul>
                <li>
                  <a href="#">
                    <img src="/web/images/language1.png" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="/web/images/language2.png" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="/web/images/language3.png" />
                  </a>
                </li>
              </ul>
            </div>

            {RenderLogin()}
          </div>
        </div>
        <div className="gnb">
          <ul>
            <li>
              <a
                href="#"
                className="fade_1_1_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.deposit,
                  });
                }}
              >
                <img src="/web/images/gnb1.png" />
                {/* <img src="/web/images/gnb1over.png" /> */}
              </a>
            </li>
            <li>
              <a
                href="#"
                className="fade_2_1_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.withdraw,
                  });
                }}
              >
                <img src="/web/images/gnb2.png" />
                {/* <img src="/web/images/gnb2over.png" /> */}
              </a>
            </li>
            <li>
              <a
                href="#"
                className="fade_3_1_open"
                onClick={() => {
                  this.setState({
                     popupStatuses:  popupView.point,
                  });
                }}
              >
                <img src="/web/images/gnb3.png" />
              </a>
            </li>

            <li>
              <a
                href="#"
                className="fade_3_1_open"
                onClick={() => {
                  this.setState({
                     popupStatuses:  popupView.comp,
                  });
                }}
              >
                <img src="/web/images/comp.png" style={{width:'53px'}} />
              </a>
            </li>
            <li>
              <a
                href="#"
                className="fade_5_1_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.user,
                  });
                }}
              >
                <img src="/web/images/gnb5.png" />
                {/* <img src="/web/images/gnb5over.png" /> */}
              </a>
            </li>
            <li>
              <a
                href="#"
                className="fade_6_1_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.notice,
                  });
                }}
              >
                <img src="/web/images/gnb6.png" />
                {/* <img src="/web/images/gnb6over.png" /> */}
              </a>
            </li>
            <li>
              <a
                href="#"
                className="fade_7_1_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.help,
                  });
                }}
              >
                <img src="/web/images/gnb7.png" />
                {/* <img src="/web/images/gnb7over.png" /> */}
              </a>
            </li>
          </ul>
        </div>
        <div className="notice_wrap">
          <div className="notice_box">
            <img src="/web/images/notice_title.png" /> 안녕하십니까? 카지노에
            오신것을 진심으로 환영합니다.{" "}
            <span className="font05">
              입금 계좌는 수시로 변경되오니 입금전 꼭 계좌번호를 고객센터로 문의
            </span>
            해 주시기 바랍니다.
          </div>
        </div>

        {RenderPopup()}
      </div>
    );
  }
}

topBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let TopBar = withStyles(styles, { withTheme: true })(topBar);
export default TopBar;
