import React, { Component } from "react";

import styled from "styled-components";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
// import Iframe from "react-iframe";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Footer } from "../share/footer";

import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
} from "../../utility/help";

import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";

import { FxService } from "../../service/fx.service";
import { UserService } from "../../service/user.service";
import { BalanceService } from "../../service/balance.service";

import { TopBar } from "../share/topBar";
import { BetBtn } from "../share/bet-btn";
import { MiniService } from "../../service/mini.service";
import { colors } from "@material-ui/core";

const drawerWidth = 240;

const styles = (theme: any) => ({
  root: {
    display: "flex",
    backgroundColor: "#424242",
  },
  paper: {
    paddingTop: 100,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },
});

const CustomCardContent = styled(CardContent)`
  padding: 0px;
`;

const CustomButton = styled(Button)`
  width: 29%;
  font-size: 11px;
  margin: 4px;
`;

// const classes = useStyles();

interface Props {
  classes: any;
  session: any;
  user: any;
}

interface State {
  user: any;
  minis: any;
  deposits: any;
  history: any;
  endDate: any;
  minute: number;
  maxCount: number;
  page: number;
  balance: number;
  point: number;
  
}

class point extends Component<Props, State> {
  divElement: any;
  static propTypes: { classes: PropTypes.Validator<object> };
  miniService = new MiniService();
  balanceService = new BalanceService();
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      minis: null,
      history: [],
      deposits: [],
      minute: 1,
      maxCount: 1,
      endDate: "",
      page: 1,
      balance: 0,
      point: 0,
      
      user: {
        balance: 0,
      },
    };
  }

  componentDidMount() {
    this.miniService.getMiniGameByGameType("pwball").then((s: any) => {
      if (s.status === "success") {
        this.setState({ minis: s.minis[0] });
      }
    });

    this.balanceService.getDepositList().then((data: any) => {
      if (data.status === "success") {
        this.setState({ deposits: data.deposits, maxCount: data.maxCount });
      }
    });
  }

  createData = (
    name: any,
    balnce: any,
    fat: any,
    maxBalanceCount: any,
    carbs: any,
    protein: any
  ) => {
    return {
      name,
      balnce,
      fat,
      maxBalanceCount,
      buyCount: 0,
      sellCount: 0,
      carbs,
      protein,
    };
  };

  updateUserDate = () => {
    // this.userService.getUserInfo().then((ss) => {
    //   if (ss.status === "success") {
    //     this.setState({ user: ss.user });
    //   }
    // });
  };

  

  
  handleChangeToBalance = () => {
    if(this.state.balance <= 0 ){
        confirmAlert({
            title: "벨런스",
            message: "벨런스를 입력해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });
          return ;
    }

    confirmAlert({
        title: "벨런스",
        message: "보유머니를 입금 하시겠습니까?",
        buttons: [
          {
            label: "확인",
            onClick: () => {

                this.balanceService.user_balance_to_point(this.state.balance).then((data: any) => {
                    if (data.status === "success") {
                      confirmAlert({
                        title: "벨런스",
                        message: "벨런스를 금고머니로 변경하였습니다.",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              window.location.reload()
                            },
                          },
                        ],
                      });
            
                    }
                });
            },
          },

          {
            label: "취소",
            onClick: () => {

            },
          },

        ],
      });
     

  };

  handleChangeToPoint = () => {
    if( this.state.point <= 0 ){
        confirmAlert({
            title: "금고머니",
            message: "금고머니를 입력해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });
          return ;
    }

    confirmAlert({
        title: "금고머니",
        message: "금고머니를 출금하시겠습니까?",
        buttons: [
          {
            label: "확인",
            onClick: () => {
                this.balanceService.user_point_to_money(this.state.point).then((data: any) => {
                    if (data.status === "success") {
                        confirmAlert({
                            title: "금고머니",
                            message: "금고머니를 벨런스로 변경하였습니다.",
                            buttons: [
                              {
                                label: "확인",
                                onClick: () => {
                                  window.location.reload()
                                },
                              },
                            ],
                          });
                  }
                });
            },
          },

          {
            label: "취소",
            onClick: () => {

            },
          },

        ],
      });
     

  };





  RenderDepositTable = (rows: any) => {
    return (
      <Grid container spacing={1}>
        {/* <Grid item xs={2}></Grid> */}

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table
              size="small"
              aria-label="a dense table"
              style={{ backgroundColor: "#484848" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="right">충전 금액</TableCell>
                  <TableCell align="right">충전 일</TableCell>
                  <TableCell align="right">상태</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any) => (
                  <TableRow key={row.name}>
                    <TableCell align="right">
                      {ConverMoeny(row.balance)}
                    </TableCell>
                    <TableCell align="right">
                      {ConvertDate(row.regdate)}
                    </TableCell>
                    <TableCell align="right">
                      {ConvertBalanceStateToText(row.status)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  };

  render() {
    const classes = this.props.classes;
    const deposits = this.state.deposits;

    return (
      <React.Fragment>
        {/* <div className="header_wrap">
          <TopBar user={this.props.user}></TopBar>
        </div> */}

        <div className="contents_wrap">
          <div className="contents_box">
            <div className="con_box00">
              <div className="info_wrap">
                <div className="info2">
                  수표입금시 입금처리 절대 되지 않습니다.
                </div>
                <div className="info3">
                  <span className="font06">
                    최소 입금액은 3만원이상, 만원단위
                  </span>
                  로 신청가능, 입금전 반드시 계좌문의 하시기바랍니다.
                  <br />
                </div>
              </div>
            </div>


            <div className="con_box05">
              <table className="write_title_top" style={{ width: "100%" }}>
              <tr>
                  <td className="write_title">금고 보유금</td>
                  <td className="write_basic">
                      <p style={{ padding: "5px", fontWeight: "bold" }}>{ConverMoeny(this.props.user.point)}</p>
                  </td>
                </tr>
                <tr>
                  <td className="write_title">충전금액</td>
                  <td className="write_basic">
                    <input
                      className="input1"
                      value={this.state.balance}
                      onChange={(e) =>
                        this.setState({
                          balance: Number(e.target.value),
                        })
                      }
                    />
                    <table style={{ width: "100%" }}>
                      <tr key="table_balance">
                        <td align="center" key="table_balance_1_1">
                          <a
                           onClick={()=> this.handleChangeToBalance()}
                          >
                            <span className="m_btn1">입금하기</span>
                          </a>
                        </td>
                   
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td className="write_title"></td>
                  <td className="write_basic"></td>
                </tr>
                <tr>
                  <td className="write_title"></td>
                  <td className="write_basic"></td>
                </tr>

                <tr>
                  <td className="write_title">금고출금</td>
                  <td className="write_basic">
                    <input
                      className="input1"
                      value={this.state.point}
                      onChange={(e) =>
                        this.setState({
                          point: Number(e.target.value),
                        })
                      }
                    />
                    <table style={{ width: "100%" }}>
                      <tr key="table_balance">
                        <td align="center" key="table_balance_1_1">
                          <a
                             onClick={()=> this.handleChangeToPoint()}
                          >
                            <span className="m_btn1">출금하기</span>
                          </a>
                        </td>
                   
                      </tr>
                    </table>
                  </td>
                </tr>
              
              </table>
            </div>
            <div className="con_box05">
              <div className="info_wrap">
                <div className="info3">
                  23:50 ~ 00:30, 휴일 다음 첫 영업일 새벽에는 은행점검으로 인해
                  계좌이체가 지연될 수 있습니다.
                  <br />위 시간 이외에도 몇몇 은행은 추가적 점검시간이 따로
                  있으니 이점 유념하시기 바랍니다.
                </div>
              </div>
            </div>
 
          </div>
        </div>

        <CssBaseline />

        <Footer />
      </React.Fragment>
    );
  }
}

point.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let Point = withStyles(styles, { withTheme: true })(point);
export default Point;
