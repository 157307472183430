import React, { Component, useState } from "react";

import { HashRouter as Router, Route, Switch } from "react-router-dom";

import { createGlobalStyle } from "styled-components";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { Login } from "./main/login";
import { Reg } from "./main/reg";
import { Game } from "./main/game";
import { Deposit } from "./main/deposit";
import { Withdraw } from "./main/withdraw";
import { Point } from "./main/point";
import { Help } from "./main/help";
import { Notice } from "./main/notice";
import { MyPage } from "./main/mypage";
import { Rolling } from "./main/rolling";

import { DepositAndWithdraw } from "./main/depositandwithdraw";
import { Note } from "./main/note";
import { TopBar } from "./share/topBar";
import { NotePopup } from "./share/notepopup";



import { UserService } from "../service/user.service";

const GlobalStyles = createGlobalStyle`
body{
  color: #FFC107;
  background-color: #000;
}

.body{
  color: #FFC107;
}

.MuiPaper-root {
  background-color: #000;
}

.popup-content {
  width: 100%;
}

.MuiPaginationItem-root{
  color: #ffffff;
}

.MuiPaginationItem-outlined{
  border: 1px solid #9C27B0;
}
`;

interface Props {
  authenticated: boolean;
  session: string;
  tryLogin: (id: any, pw: any) => void;
  tryLoginOut: () => any;
  SetCookie: (name: string, data: any) => any;
  GetCookie: (name: string) => any;
}

interface State {
  user: {
    id: string;
    balance: number;
    point: number;
  };
}

export class Mobile extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);
    this.state = {
      user: {
        id: "",
        balance: 0,
        point: 0,
      },
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.updateUser();
    }, 5000);
    this.updateUser();
  }

  updateUser = () => {
    this.userService.healthCheck().then((s: any) => {
      if (s.status === "success") {
        let user = {
          id: s.user.id,
          balance: s.user.balance,
          point: s.user.point,
        };

        if (JSON.stringify(user) !== JSON.stringify(this.state.user)) {
          this.setState({ user: user });
        }
      } else {
        // window.location.hash = "/";
      }
    });
  };

  render() {
    return (
      <Router>
        <div className="header_wrap">
          <TopBar
            user={this.state.user}
            tryLoginOut={this.props.tryLoginOut}
          ></TopBar>
        </div>

        <Switch>
          {/* <Route
            exact
            path="/"
            render={(props: any) => (
              <Login
                authenticated={this.props.authenticated}
                tryLogin={this.props.tryLogin}
              />
            )}
          ></Route> */}
          <Route exact path="/reg">
            <Reg />
          </Route>
          <Route exact path="/">
            <Game session={this.props.session} user={this.state.user} />
          </Route>
          <Route exact path="/login">
            <Login tryLogin={this.props.tryLogin} />
            
          </Route>
          <Route exact path="/notice">
            <Notice session={this.props.session} user={this.state.user} />
          </Route>
          <Route exact path="/deposit">
            <Deposit session={this.props.session} user={this.state.user} />
          </Route>
          <Route exact path="/withdraw">
            <Withdraw session={this.props.session} user={this.state.user} />
          </Route>
          <Route exact path="/point">
            <Point session={this.props.session} user={this.state.user} />
          </Route>
          <Route exact path="/rolling">
            <Rolling   user={this.state.user} />
          </Route>    
          <Route exact path="/help">
            <Help user={this.state.user} />
          </Route>
          <Route
            exact
            path="/mypage"
            render={(props) => {
              return <MyPage user={this.state.user} />;
              // if (authenticated) return <MyPage session={cookie.session} />;
              // else {
              //   confirmAlert({
              //     title: "충전/환전",
              //     message: " 로그인이후에 사용가능합니다.",
              //     buttons: [
              //       {
              //         label: "확인",
              //         onClick: () => {
              //           window.location.hash = "/";
              //         },
              //       },
              //     ],
              //   });
              // }
            }}
          ></Route>
          <Route
            exact
            path="/depositandwithdraw"
            render={(props) => {
              return (
                <DepositAndWithdraw
                  session={this.props.session}
                  user={this.state.user}
                />
              );
              // if (authenticated) return <MyPage session={cookie.session} />;
              // else {
              //   confirmAlert({
              //     title: "충전/환전",
              //     message: " 로그인이후에 사용가능합니다.",
              //     buttons: [
              //       {
              //         label: "확인",
              //         onClick: () => {
              //           window.location.hash = "/";
              //         },
              //       },
              //     ],
              //   });
              // }
            }}
          ></Route>
          <Route
            exact
            path="/note"
            render={(props) => {
              return (
                <Note session={this.props.session} user={this.state.user} />
              );
              // if (authenticated) return <MyPage session={cookie.session} />;
              // else {
              //   confirmAlert({
              //     title: "충전/환전",
              //     message: " 로그인이후에 사용가능합니다.",
              //     buttons: [
              //       {
              //         label: "확인",
              //         onClick: () => {
              //           window.location.hash = "/";
              //         },
              //       },
              //     ],
              //   });
              // }
            }}
          ></Route>
        </Switch>
        <NotePopup
          SetCookie={this.props.SetCookie}
          GetCookie={this.props.GetCookie}
        ></NotePopup>
        <GlobalStyles />
      </Router>
    );
  }
}
